.mapboxgl-popup-close-button {
	font-size: 25px;
	color: #BFBFBF
}

.mapboxgl-popup-content {
	padding: 0px;
	box-shadow: 2.5px -2.5px 5px #BFBFBF;
}

.mapboxgl-ctrl-geocoder {
	min-width: 0px !important;
	width: auto !important;
	margin-left: 10px !important
}

.mapboxgl-ctrl-geocoder--input {
	font-size: 15px !important;
	height: 35px !important;
}

.mapboxgl-ctrl-geocoder--icon-search  {
	top: 8px !important;
	left: 10px !important;
	height: 20px !important;
	width: 20px !important;
}

@media (max-width: 425px) {
	.mapboxgl-ctrl-geocoder--input {
		font-size: 12.5px !important;
		padding-left: 35px !important;
	}
}

.mapboxgl-ctrl-icon {
	box-shadow: 2px 2px 2px gray;
	background-color: white;
}

.mapboxgl-ctrl-group:not(:empty) {
	background-color: transparent;
	box-shadow: none;
}

.mapboxgl-ctrl button .mapboxgl-ctrl-icon {
	background-color: transparent;
}

.mapboxgl-ctrl-group button:focus {
	box-shadow: none;
}

.MuiBackdrop-root {
	background-color: rgba(255, 255, 255, 0.5) !important;
}

.MuiSwitch-colorSecondary.Mui-checked {
	color: white !important;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
	background-color: white !important;
}

.mapboxgl-ctrl-top-left {
	z-index: 3;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
	background-color: white;
	border-radius: 5px;
	border: 2px solid rgba(0, 0, 0, 0.2);
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23383838'%3E%3Cpath d='M10.5 14l4-8 4 8h-8z'/%3E%3Cpath d='M10.5 16l4 8 4-8h-8z' fill='%23ccc'/%3E%3C/svg%3E");
}

.mapboxgl-ctrl button.mapboxgl-ctrl-compass:hover .mapboxgl-ctrl-icon {
	background-color: #F2F2F2;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon {
	background-color: white;
	border-radius: 5px;
	border: 2px solid rgba(0, 0, 0, 0.2);
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' fill='%23383838'%3E%3Cpath d='M10 4C9 4 9 5 9 5v.1A5 5 0 005.1 9H5s-1 0-1 1 1 1 1 1h.1A5 5 0 009 14.9v.1s0 1 1 1 1-1 1-1v-.1a5 5 0 003.9-3.9h.1s1 0 1-1-1-1-1-1h-.1A5 5 0 0011 5.1V5s0-1-1-1zm0 2.5a3.5 3.5 0 110 7 3.5 3.5 0 110-7z'/%3E%3Ccircle cx='10' cy='10' r='2'/%3E%3C/svg%3E");
}

.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate:hover .mapboxgl-ctrl-icon {
	background-color: #F2F2F2;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon {
	background-color: white;
	border-radius: 5px 5px 0px 0px;
	border: 2px solid rgba(0, 0, 0, 0.2);
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23383838'%3E%3Cpath d='M14.5 8.5c-.75 0-1.5.75-1.5 1.5v3h-3c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h3v3c0 .75.75 1.5 1.5 1.5S16 19.75 16 19v-3h3c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-3v-3c0-.75-.75-1.5-1.5-1.5z'/%3E%3C/svg%3E");
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in:hover .mapboxgl-ctrl-icon {
	background-color: #F2F2F2;
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon {
	background-color: white;
	border-radius: 0px 0px 5px 5px;
	border: 2px solid rgba(0, 0, 0, 0.2);
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='29' height='29' viewBox='0 0 29 29' xmlns='http://www.w3.org/2000/svg' fill='%23383838'%3E%3Cpath d='M10 13c-.75 0-1.5.75-1.5 1.5S9.25 16 10 16h9c.75 0 1.5-.75 1.5-1.5S19.75 13 19 13h-9z'/%3E%3C/svg%3E");
}

.mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out:hover .mapboxgl-ctrl-icon {
	background-color: #F2F2F2;
}

.mapboxgl-ctrl-group:not(:empty) {
	margin-right: 15px;
}

.mapboxgl-canvas-container.mapboxgl-interactive, .mapboxgl-ctrl-group button.mapboxgl-ctrl-compass:hover {
	background-color: transparent;
}
